import {
  ADMIN,
  ADMIN_VIEWER,
  MODERATOR_VIEWER,
  QUALITY_ASSURANCE,
  QUALITY_ASSURANCE_HEAD,
  REGISTRAR_OFFICER,
  REGISTRAR_OFFICER_SAMARKAND,
  SUPER_ADMIN,
} from '@constants/userRoles'
import { lazy } from 'react'

import { COURSE_CREATE } from '../constants/route'

export const CourseCreateRoutes = [
  {
    path: COURSE_CREATE,
    component: lazy(() => import('../views/CourseCreate')),
    meta: {
      access: [
        SUPER_ADMIN,
        QUALITY_ASSURANCE,
        QUALITY_ASSURANCE_HEAD,
        MODERATOR_VIEWER,
        REGISTRAR_OFFICER,
        REGISTRAR_OFFICER_SAMARKAND,
        ADMIN,
        ADMIN_VIEWER,
      ],
    },
  },
]
