import {
  BACHELOR_STUDENT,
  REGISTRAR_HEAD,
  REGISTRAR_HELPER,
  REGISTRAR_OFFICER,
  REGISTRAR_OFFICER_SAMARKAND,
  SUPER_ADMIN,
  TEST_STUDENT,
} from '@constants/userRoles'
import { lazy } from 'react'

import { FORM_FORMS_FOR_STUDENT, FORM_INCOMING_FORMS, FORM_REQUEST, FORM_REQUEST_CREATE } from '../constants/route'

export const FormRoutes = [
  {
    path: FORM_FORMS_FOR_STUDENT,
    component: lazy(() => import('../views/StudentFormRequests')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, TEST_STUDENT, BACHELOR_STUDENT],
    },
  },
  {
    path: FORM_REQUEST,
    component: lazy(() => import('../components/FormRequests')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, TEST_STUDENT, BACHELOR_STUDENT],
    },
  },
  {
    path: FORM_REQUEST_CREATE,
    component: lazy(() => import('../components/FormRequestCreate')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, TEST_STUDENT, BACHELOR_STUDENT],
    },
  },
  {
    path: FORM_INCOMING_FORMS,
    component: lazy(() => import('../views/StudentFormRequests')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, REGISTRAR_HEAD, REGISTRAR_OFFICER, REGISTRAR_OFFICER_SAMARKAND, REGISTRAR_HELPER],
    },
    componentProps: {
      canEdit: true,
    },
  },
]
