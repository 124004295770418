import {
  ADMIN,
  BACHELOR_STUDENT,
  COURSE_LEADER,
  DEAN,
  REGISTRAR_OFFICER,
  REGISTRAR_OFFICER_SAMARKAND,
  STUDENTS_DEPARTMENT,
  STUDENTS_DEPARTMENT_SAMARKAND,
  SUPER_ADMIN,
  TEST_STUDENT,
} from '@src/constants/userRoles'
import { lazy } from 'react'

import { TIMETABLE } from '../constants/route'

export const TimetableRoutes = [
  {
    path: TIMETABLE,
    component: lazy(() => import('../views/Timetable')),
    meta: {
      access: [
        SUPER_ADMIN,
        ADMIN,
        TEST_STUDENT,
        BACHELOR_STUDENT,
        REGISTRAR_OFFICER,
        REGISTRAR_OFFICER_SAMARKAND,
        COURSE_LEADER,
        STUDENTS_DEPARTMENT,
        STUDENTS_DEPARTMENT_SAMARKAND,
        DEAN,
      ],
    },
  },
]
