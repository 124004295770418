import {
  ADMIN,
  ADMIN_VIEWER,
  COURSE_LEADER,
  DEAN,
  PROGRAMME_MANAGER,
  QUALITY_ASSURANCE,
  QUALITY_ASSURANCE_HEAD,
  REGISTRAR_HEAD,
  REGISTRAR_HELPER,
  REGISTRAR_OFFICER,
  REGISTRAR_OFFICER_SAMARKAND,
  STUDENTS_DEPARTMENT,
  STUDENTS_DEPARTMENT_SAMARKAND,
  SUPER_ADMIN,
} from '@constants/userRoles'
import { lazy } from 'react'

import { GROUPING, GROUP_ASSIGN } from '../constants/route'

export const GroupingRoutes = [
  {
    path: GROUPING,
    component: lazy(() => import('../views/Grouping')),
    exact: true,
    meta: {
      access: [
        SUPER_ADMIN,
        ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_OFFICER_SAMARKAND,
        REGISTRAR_HELPER,
        PROGRAMME_MANAGER,
        DEAN,
        QUALITY_ASSURANCE,
        QUALITY_ASSURANCE_HEAD,
        COURSE_LEADER,
        STUDENTS_DEPARTMENT,
        STUDENTS_DEPARTMENT_SAMARKAND,
        ADMIN_VIEWER,
      ],
    },
  },
  {
    path: GROUP_ASSIGN,
    component: lazy(() => import('../views/GroupAssign')),
    exact: true,
    meta: {
      access: [
        SUPER_ADMIN,
        ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_OFFICER_SAMARKAND,
        REGISTRAR_HELPER,
        PROGRAMME_MANAGER,
        COURSE_LEADER,
      ],
    },
  },
]
