import {
  MODERATOR_VIEWER,
  MODULE_LEADER,
  REGISTRAR_HEAD,
  REGISTRAR_OFFICER,
  REGISTRAR_OFFICER_SAMARKAND,
  SUPER_ADMIN,
} from '@constants/userRoles'
import { lazy } from 'react'

import { ASSESSMENT_OFFENSE, ASSESSMENT_REPORT } from '../constants/route'

export const OffenceRoutes = [
  {
    path: ASSESSMENT_OFFENSE,
    component: lazy(() => import('../views/Offence')),
    meta: {
      access: [
        SUPER_ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_OFFICER_SAMARKAND,
        MODERATOR_VIEWER,
        MODULE_LEADER,
      ],
    },
  },
  {
    path: ASSESSMENT_REPORT,
    component: lazy(() => import('../views/ReportMisconduct')),
    meta: {
      access: [SUPER_ADMIN, MODULE_LEADER, MODERATOR_VIEWER],
    },
  },
]
