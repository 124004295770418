import * as ROUTE from '@constants/routes'
import * as ROLES from '@constants/userRoles'
import Footer from '@layouts/components/footer'
import { store } from '@src/redux/store'
import { any } from 'ramda'
import {
  AlertOctagon,
  ArrowDown,
  ArrowUp,
  Award,
  Book,
  Briefcase,
  Calendar,
  Check,
  CheckCircle,
  Circle,
  Clipboard,
  Clock,
  CreditCard,
  DollarSign,
  Edit,
  File,
  FileText,
  Globe,
  Grid,
  HardDrive,
  Hexagon,
  Home,
  List,
  Meh,
  MessageSquare,
  Monitor,
  Navigation,
  PlusCircle,
  PlusSquare,
  Settings,
  Star,
  User,
  UserMinus,
  UserX,
  Users,
  ZapOff,
} from 'react-feather'
import { FormattedMessage } from 'react-intl'
import { generatePath } from 'react-router-dom'

// TODO complete dynamic route

export default [
  {
    id: 'home',
    title: <FormattedMessage id='main' />,
    icon: <Home size={20} />,
    navLink: ROUTE.HOME,
    access: roles => {
      return !any(role => role === ROLES.GUEST_USER)(roles)
    },
  },
  {
    id: 'studentDashboard',
    title: <FormattedMessage id='modules' />,
    icon: <Grid size={20} />,
    navLink: ROUTE.STUDENT_DASHBOARD,
    access: [ROLES.BACHELOR_STUDENT, ROLES.TEST_STUDENT, ROLES.MASTER_STUDENT],
  },
  {
    id: 'Admission',
    title: <FormattedMessage id='Admission.pageTitle' />,
    icon: <Book size={50} />,
    navLink: ROUTE.MY_APPLICATIONS,
    access: [ROLES.GUEST_USER],
  },
  // {
  //   id: 'guestApplications',
  //   title: <FormattedMessage id='applications' />,
  //   icon: <FileText size={20} />,
  //   access: [ROLES.GUEST_USER],
  //   navLink: '/guest-application',
  //   children: [
  //     {
  //       id: 'hrMyApplications',
  //       title: <FormattedMessage id='Job Applications' />,
  //       icon: <User size={50} />,
  //       navLink: ROUTE.HR_MY_APPLICATIONS,
  //       access: [ROLES.GUEST_USER],
  //     },
  //     {
  //       id: 'Admission',
  //       title: <FormattedMessage id='admission' />,
  //       icon: <Book size={50} />,
  //       navLink: ROUTE.MY_APPLICATIONS,
  //       access: [ROLES.GUEST_USER],
  //     },
  //     {
  //       id: 'GeneralAdmission',
  //       title: <FormattedMessage id='General admission' />,
  //       icon: <Book size={50} />,
  //       navLink: ROUTE.GENERAL_MY_APPLICATIONS,
  //       access: [ROLES.GUEST_USER],
  //     },
  //   ],
  // },

  {
    id: 'articulate',
    title: <FormattedMessage id='Articulate' />,
    icon: <Book size={20} />,
    navLink: ROUTE.ARTICULATE,
    access: [ROLES.BACHELOR_STUDENT, ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADMIN_SAMARKAND, ROLES.SYSTEM_MODERATOR],
  },
  {
    id: 'openSourceLinks',
    title: <FormattedMessage id='Open source' />,
    icon: <Globe size={20} />,
    navLink: ROUTE.OPEN_SOURCE,
    access: roles => {
      return !any(role => role === ROLES.GUEST_USER)(roles)
    },
  },
  {
    id: 'libraries',
    title: <FormattedMessage id='Libraries' />,
    icon: <Book size={20} />,
    access: roles => {
      return !any(role => role === ROLES.GUEST_USER)(roles)
    },
    children: [
      {
        id: 'library',
        title: <FormattedMessage id='Library' />,
        icon: <Circle size={20} />,
        navLink: '/library',
        access: roles => {
          return !any(role => role === ROLES.GUEST_USER)(roles)
        },
      },
      {
        id: 'elibrary',
        title: 'E-Library',
        icon: <Circle size={20} />,
        externalLink: true,
        newTab: true,
        navLink: 'https://unilibrary.uz/',
        access: roles => {
          return !any(role => role === ROLES.GUEST_USER)(roles)
        },
      },
      {
        id: 'ekutubxona',
        title: 'E-Kutubxona',
        icon: <Circle size={20} />,
        externalLink: true,
        newTab: true,
        navLink: 'http://library.slash.uz',
        access: roles => {
          return !any(role => role === ROLES.GUEST_USER)(roles)
        },
      },
      {
        id: 'emerald',
        title: 'Emerald',
        icon: <Circle size={20} />,
        externalLink: true,
        newTab: true,
        navLink: 'https://www.emerald.com/insight/',
        access: roles => {
          return !any(role => role === ROLES.GUEST_USER)(roles)
        },
      },
    ],
  },

  {
    id: 'documentManagement',
    title: <FormattedMessage id='documents' />,
    access: roles => {
      return (
        any(role => [ROLES.TEST_STUDENT].includes(role), roles) ||
        !any(role => [ROLES.GUEST_USER, ROLES.BACHELOR_STUDENT, ROLES.MASTER_STUDENT].includes(role), roles)
      )
    },
    navLink: ROUTE.DOCUMENT_MANAGEMENT,
    icon: <FileText size={20} />,
    children: [
      {
        id: 'documentManagementIncoming',
        title: <FormattedMessage id='incoming' />,
        icon: <ArrowDown size={20} />,
        access: roles => {
          return (
            // roles.find(role => role === ROLES.TEST_STUDENT) ||
            any(role => [ROLES.TEST_STUDENT].includes(role), roles) ||
            !any(role => [ROLES.GUEST_USER, ROLES.BACHELOR_STUDENT, ROLES.MASTER_STUDENT].includes(role), roles)
          )
        },
        navLink: ROUTE.DOCUMENT_MANAGEMENT_INCOMING,
      },
      {
        id: 'documentManagementOutgoing',
        title: <FormattedMessage id='outgoing' />,
        icon: <ArrowUp size={20} />,
        access: roles => {
          return (
            !any(role => [ROLES.GUEST_USER, ROLES.BACHELOR_STUDENT, ROLES.MASTER_STUDENT].includes(role), roles) ||
            any(role => [ROLES.TEST_STUDENT].includes(role), roles)
          )
        },
        navLink: ROUTE.DOCUMENT_MANAGEMENT_OUTGOING,
      },
      {
        id: 'documentGroup',
        title: <FormattedMessage id='docGroup' />,
        icon: <Users size={20} />,
        // access: roles => {
        //   return !any(
        //     role => [ROLES.GUEST_USER, ROLES.BACHELOR_STUDENT, ROLES.MASTER_STUDENT].includes(role),
        //     // role => [ROLES.GUEST_USER].includes(role),
        //     roles
        //   )
        // },
        access: [ROLES.SUPER_ADMIN],
        navLink: ROUTE.DOCUMENT_MANAGEMENT_GROUP,
      },
      {
        id: 'documentForModerator',
        title: <FormattedMessage id='documents' />,
        icon: <Users size={20} />,
        // access: roles => {
        //   return !any(
        //     role => [ROLES.GUEST_USER, ROLES.BACHELOR_STUDENT, ROLES.MASTER_STUDENT].includes(role),
        //     // role => [ROLES.GUEST_USER].includes(role),
        //     roles
        //   )
        // },
        access: [ROLES.SUPER_ADMIN, ROLES.DOCUMENT_MANAGEMENT_MODERATOR],
        navLink: ROUTE.DOCUMENT_FOR_MODERATOR,
      },
    ],
  },

  {
    id: 'accountingHeaderHeader',
    header: <FormattedMessage id='accounting' />,
    access: [ROLES.SUPER_ADMIN, ROLES.ACCOUNTING_HEAD, ROLES.ADMIN_VIEWER, ROLES.ACCOUNTING_HEAD_VIEWER],
  },
  {
    id: 'accounting',
    title: <FormattedMessage id='studentContracts' />,
    icon: <User size={20} />,
    navLink: ROUTE.ACCOUNTING_CONTRACTS,
    access: [],
  },
  {
    id: 'payments',
    title: <FormattedMessage id='payments' />,
    icon: <CreditCard size={20} />,
    navLink: ROUTE.PAYMENTS,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ACCOUNTING_HEAD,
      ROLES.ACCOUNTING_HEAD_VIEWER,
      ROLES.BACHELOR_STUDENT,
      ROLES.MASTER_STUDENT,
    ],
  },
  {
    id: 'debit',
    title: <FormattedMessage id='debit' />,
    icon: <DollarSign size={20} />,
    navLink: ROUTE.DEBIT,
    access: [ROLES.SUPER_ADMIN, ROLES.BACHELOR_STUDENT, ROLES.MASTER_STUDENT],
  },
  {
    id: 'timetable',
    title: <FormattedMessage id='timetable' />,
    icon: <Calendar size={20} />,
    navLink: ROUTE.TIMETABLE,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.TEST_STUDENT,
      ROLES.BACHELOR_STUDENT,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.COURSE_LEADER,
      ROLES.STUDENTS_DEPARTMENT,
      ROLES.STUDENTS_DEPARTMENT_SAMARKAND,
      ROLES.DEAN,
    ],
  },

  {
    id: 'calendar',
    title: <FormattedMessage id='calendar' />,
    icon: <Calendar size={20} />,
    navLink: ROUTE.CALENDAR,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.BACHELOR_STUDENT,
      ROLES.MASTER_STUDENT,
      ROLES.TEST_STUDENT,
      ROLES.REGISTRAR_HEAD,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.REGISTRAR_HELPER,
      ROLES.PROGRAMME_MANAGER,
      ROLES.TEACHING_AND_LEARNING_OFFICER,
      ROLES.TEACHER,
      ROLES.MODULE_LEADER,
      ROLES.MODERATOR_VIEWER,
      ROLES.ADMIN_VIEWER,
    ],
  },
  {
    id: 'admissionApplications',
    header: <FormattedMessage id='Admission Applications' />,
    access: [ROLES.SUPER_ADMIN, ROLES.ADMISSION_MODERATOR, ROLES.ADMISSION_VIEWER],
  },
  {
    id: 'hrAdminApplications',
    title: <FormattedMessage id='HR Applications' />,
    icon: <Briefcase size={20} />,
    navLink: ROUTE.HR_APPLICATIONS,
    access: [ROLES.SUPER_ADMIN, ROLES.HR_MANAGER, ROLES.HR_HEAD],
  },

  // {
  //   id: 'admissionForAdmin',
  //   title: 'Admission',
  //   icon: <HardDrive size={20} />,
  //   navLink: ROUTE.HR,
  //   access: [ROLES.SUPER_ADMIN],
  //   children: [
  //     {
  //       id: 'admissionAdmin',
  //       title: 'Admission',
  //       icon: <Circle size={20} />,
  //       navLink: ROUTE.HR_ADMIN_APPLICATION_FORM,
  //       access: [ROLES.SUPER_ADMIN],
  //     },
  //     {
  //       id: 'admissionAdminCategory',
  //       title: 'Admission Category',
  //       icon: <Circle size={20} />,
  //       navLink: ROUTE.HR_ADMIN_APPLICATION_CATEGORY,
  //       access: [ROLES.SUPER_ADMIN],
  //     },
  //   ],
  // },

  // {
  //   id: 'AdminStCourseApplications',
  //   title: <FormattedMessage id='Course Applications' />,
  //   icon: <BookOpen size={20} />,
  //   navLink: ROUTE.ST_COURSE_APPLICATIONS,
  //   access: [ROLES.ADMISSION_MODERATOR],
  // },
  // {
  //   id: 'AdminAdmissionApplications',
  //   title: <FormattedMessage id='Admission Applications' />,
  //   icon: <Book size={20} />,
  //   navLink: ROUTE.APPLICATIONS,
  //   access: [ROLES.ADMISSION_MODERATOR, ROLES.ADMISSION_VIEWER],
  // },
  // {
  //   id: 'GeneralAdmissionApplications',
  //   title: <FormattedMessage id='General Admission Applications' />,
  //   icon: <Folder size={20} />,
  //   navLink: ROUTE.GENERAL_APPLICATIONS,
  //   access: [ROLES.ADMISSION_MODERATOR, ROLES.MARKETING],
  // },
  // {
  //   header: <FormattedMessage id='Information' />,
  //   access: [ROLES.ADMISSION_MODERATOR, ROLES.SUPER_ADMIN],
  // },
  {
    id: 'stCourseAdmissionForAdmin',
    title: 'stCourseAdmission',
    icon: <HardDrive size={20} />,
    navLink: ROUTE.ST_COURSE,
    access: [ROLES.SUPER_ADMIN],
    children: [
      {
        id: 'stCourseAdmissionAdmin',
        title: 'ST-Course Admission',
        icon: <Circle size={20} />,
        navLink: ROUTE.ST_COURSE_ADMIN_APPLICATION_FORM,
        access: [ROLES.SUPER_ADMIN],
      },
      {
        id: 'stCourseAdmissionAdminCategory',
        title: 'ST-Course Admission Category',
        icon: <Circle size={20} />,
        navLink: ROUTE.ST_COURSE_ADMIN_APPLICATION_CATEGORY,
        access: [ROLES.SUPER_ADMIN],
      },
    ],
  },
  {
    id: 'accounts',
    title: <FormattedMessage id='accounts' />,
    icon: <User size={20} />,
    navLink: ROUTE.ACCOUNTS,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.REGISTRAR_HEAD,
      ROLES.HR_MANAGER,
      ROLES.HR_HEAD,
      ROLES.DOCUMENT_MANAGEMENT_MODERATOR,
      ROLES.ADMIN,
    ],
  },
  {
    id: 'roles',
    title: <FormattedMessage id='roles' />,
    icon: <Star size={20} />,
    navLink: ROUTE.ROLES,
    access: [ROLES.SUPER_ADMIN, ROLES.DOCUMENT_MANAGEMENT_MODERATOR],
  },

  {
    id: 'jobPositions',
    title: <FormattedMessage id='Job Positions' />,
    icon: <Users size={20} />,
    navLink: ROUTE.JOB_POSITION,
    access: [ROLES.SUPER_ADMIN, ROLES.HR_MANAGER, ROLES.HR_HEAD, ROLES.ADMIN, ROLES.ADMIN_SAMARKAND],
  },

  {
    id: 'departments',
    title: <FormattedMessage id='departments' />,
    icon: <PlusSquare size={20} />,
    navLink: '/departments',
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.REGISTRAR_HEAD,
      // ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_HELPER,
      ROLES.HR_MANAGER,
      ROLES.HR_HEAD,
    ],
  },

  {
    id: 'vacancyPositions',
    title: <FormattedMessage id='VacancyPositions.pageTitle' />,
    icon: <Briefcase size={20} />,
    navLink: ROUTE.VACANCY_POSITION,
    access: [ROLES.SUPER_ADMIN, ROLES.HR_MANAGER, ROLES.HR_HEAD, ROLES.ADMIN, ROLES.ADMIN_SAMARKAND],
  },

  {
    id: 'institution',
    title: <FormattedMessage id='institution' />,
    icon: <HardDrive size={20} />,
    navLink: ROUTE.INSTITUTION,
    children: [
      {
        id: 'institutionSettings',
        title: 'Page Settings',
        icon: <Settings size={20} />,
        navLink: ROUTE.INSTITUTION_PAGE_SETTINGS,
        access: [ROLES.SUPER_ADMIN],
      },
    ],
  },
  {
    header: 'Courses',
    access: [ROLES.SUPER_ADMIN, ROLES.SHORT_TERM_COURSE_LISTENER],
  },
  {
    id: 'shortTermCourse',
    title: 'Short-term',
    icon: <Monitor size={20} />,
    navLink: ROUTE.SHORT_TERM_COURSE_STUDENT,
    access: [ROLES.SUPER_ADMIN, ROLES.SHORT_TERM_COURSE_LISTENER],
  },
  {
    id: 'shortTermCourseCreate',
    title: 'Create',
    icon: <Monitor size={20} />,
    navLink: ROUTE.SHORT_TERM_COURSE_LIST,
    access: [ROLES.SUPER_ADMIN],
  },
  {
    id: 'shortTermCourseCategoryCreate',
    title: 'Course Categories',
    icon: <Clipboard size={20} />,
    navLink: ROUTE.SHORT_TERM_COURSE_CATEGORY_LIST,
    access: [ROLES.SUPER_ADMIN],
  },
  {
    header: 'Academic',
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      // ROLES.QUALITY_ASSURANCE,
      ROLES.MODERATOR_VIEWER,
      ROLES.REGISTRAR_HEAD,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.REGISTRAR_HELPER,
      ROLES.PROGRAMME_MANAGER,
      ROLES.TEACHING_AND_LEARNING_OFFICER,
      ROLES.TEACHER,
      ROLES.MODULE_LEADER,
      ROLES.ADMIN_VIEWER,
    ],
  },
  {
    id: 'academicYears',
    title: <FormattedMessage id='academicYear' />,
    icon: <Clock size={20} />,
    navLink: ROUTE.ACADEMIC_YEARS,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.MODERATOR_VIEWER,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.ADMIN_VIEWER,
    ],
  },
  {
    id: 'students',
    title: <FormattedMessage id='students' />,
    icon: <Users />,
    navLink: ROUTE.STUDENTS,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.MODERATOR_VIEWER,
      ROLES.REGISTRAR_HEAD,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.REGISTRAR_HELPER,
      ROLES.COURSE_LEADER,
      ROLES.DEAN,
      ROLES.STUDENTS_DEPARTMENT,
      ROLES.STUDENTS_DEPARTMENT_SAMARKAND,
      ROLES.SYSTEM_MODERATOR,
      ROLES.ACCOUNTING_HEAD,
      ROLES.ACCOUNTING_HEAD_VIEWER,
      ROLES.AMO_CRM_OPERATOR,
      ROLES.STUDENT_VIEWER,
      ROLES.STUDENT_VIEWER_SAMARKAND,
      ROLES.ARCHIVIST,
      ROLES.EXAM_HEAD,
      ROLES.ADMIN_VIEWER,
      ROLES.ID_CARD_PRINTER,
    ],
  },
  {
    id: 'inactiveStudents',
    title: <FormattedMessage id='inactiveStudents' />,
    icon: <HardDrive size={20} />,
    navLink: ROUTE.INACTIVE_STUDENTS,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.EXAM_HEAD,
      ROLES.ADMIN_VIEWER,
      ROLES.ID_CARD_PRINTER,
      ROLES.STUDENTS_DEPARTMENT,
      ROLES.STUDENTS_DEPARTMENT_SAMARKAND,
    ],
    children: [
      {
        id: 'withdrawal',
        title: 'Withdrawal',
        icon: <Circle size={20} />,
        navLink: ROUTE.WITHDRAWAL_STUDENTS,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMIN,
          ROLES.ADMIN_SAMARKAND,
          ROLES.ACCOUNTING_HEAD,
          ROLES.ACCOUNTING_HEAD_VIEWER,
          ROLES.EXAM_HEAD,
          ROLES.ADMIN_VIEWER,
          ROLES.ID_CARD_PRINTER,
          ROLES.STUDENTS_DEPARTMENT,
          ROLES.STUDENTS_DEPARTMENT_SAMARKAND,
        ],
      },
      {
        id: 'gapyearStudents',
        title: 'Gap year students',
        icon: <Circle size={20} />,
        navLink: ROUTE.GAPYEAR_STUDENTS,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMIN,
          ROLES.ADMIN_SAMARKAND,
          ROLES.ACCOUNTING_HEAD,
          ROLES.ACCOUNTING_HEAD_VIEWER,
          ROLES.EXAM_HEAD,
          ROLES.ADMIN_VIEWER,
          ROLES.ID_CARD_PRINTER,
          ROLES.STUDENTS_DEPARTMENT,
          ROLES.STUDENTS_DEPARTMENT_SAMARKAND,
        ],
      },
      {
        id: 'inactiveStudent',
        title: 'Inactive students',
        icon: <ZapOff size={20} />,
        navLink: ROUTE.INACTIVE_STUDENT,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMIN,
          ROLES.ADMIN_SAMARKAND,
          ROLES.ACCOUNTING_HEAD,
          ROLES.ACCOUNTING_HEAD_VIEWER,
          ROLES.EXAM_HEAD,
          ROLES.ADMIN_VIEWER,
          ROLES.STUDENTS_DEPARTMENT,
          ROLES.STUDENTS_DEPARTMENT_SAMARKAND,
        ],
      },
      {
        id: 'deletedStudents',
        title: 'Deleted students',
        icon: <UserX size={20} />,
        navLink: ROUTE.DELETED_STUDENTS,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMIN,
          ROLES.ACCOUNTING_HEAD,
          ROLES.ACCOUNTING_HEAD_VIEWER,
          ROLES.EXAM_HEAD,
          ROLES.ADMIN_VIEWER,
          ROLES.STUDENTS_DEPARTMENT,
          ROLES.STUDENTS_DEPARTMENT_SAMARKAND,
        ],
      },
      {
        id: 'graduatedStudents',
        title: 'Graduated students',
        icon: <Users size={20} />,
        navLink: ROUTE.GRADUATED_STUDENTS,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMIN,
          ROLES.ADMIN_SAMARKAND,
          ROLES.ACCOUNTING_HEAD,
          ROLES.ACCOUNTING_HEAD_VIEWER,
          ROLES.EXAM_HEAD,
          ROLES.ADMIN_VIEWER,
        ],
      },
    ],
  },
  {
    id: 'Applicants',
    title: <FormattedMessage id='applicants' />,
    icon: <HardDrive size={20} />,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.ADMISSION_ARCHIVE_TASHKENT,
      ROLES.ADMISSION_ARCHIVE_SAMARKAND,
      ROLES.ADMISSION_ARCHIVE_OPERATOR,
      ROLES.ACCOUNTING_HEAD,
      ROLES.ACCOUNTING_HEAD_VIEWER,
    ],
    children: [
      {
        id: 'applicants',
        title: <FormattedMessage id='applicants' />,
        icon: <Users size={20} />,
        navLink: ROUTE.GUEST_USERS,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMISSION_MODERATOR,
          ROLES.AMO_CRM_OPERATOR_ADMISSION,
          ROLES.ADMISSION_ARCHIVE_TASHKENT,
          ROLES.ADMISSION_ARCHIVE_SAMARKAND,
          ROLES.ADMISSION_ARCHIVE_OPERATOR,
          ROLES.ACCOUNTING_HEAD,
          ROLES.ACCOUNTING_HEAD_VIEWER,
        ],
      },
      {
        id: 'transferApplicants',
        title: <FormattedMessage id='transfer' />,
        icon: <Users size={20} />,
        navLink: `${generatePath(ROUTE.TRANSFER_APPLICANTS, { applicant_type: 'transfer' })}`,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMISSION_MODERATOR,
          ROLES.AMO_CRM_OPERATOR_ADMISSION,
          ROLES.ADMISSION_APPOINTMENT_TEACHER,
          ROLES.ADMISSION_ARCHIVE_TASHKENT,
          ROLES.ADMISSION_ARCHIVE_SAMARKAND,
          ROLES.ADMISSION_ARCHIVE_OPERATOR,
          ROLES.ACCOUNTING_HEAD,
          ROLES.ACCOUNTING_HEAD_VIEWER,
        ],
      },
      {
        id: 'secondarySpecApplicants',
        title: <FormattedMessage id='secondarySpecialization' />,
        icon: <Users size={20} />,
        navLink: `${generatePath(ROUTE.SECONDARY_SPECIALIZATION_APPLICANTS, { applicant_type: 'secondary-spec' })}`,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMISSION_MODERATOR,
          ROLES.AMO_CRM_OPERATOR_ADMISSION,
          ROLES.ADMISSION_APPOINTMENT_TEACHER,
          ROLES.ADMISSION_ARCHIVE_TASHKENT,
          ROLES.ADMISSION_ARCHIVE_SAMARKAND,
          ROLES.ADMISSION_ARCHIVE_OPERATOR,
          ROLES.ACCOUNTING_HEAD,
          ROLES.ACCOUNTING_HEAD_VIEWER,
        ],
      },
      {
        id: 'masterApplicants',
        title: <FormattedMessage id='masterApplicants' />,
        icon: <Users size={20} />,
        navLink: `${generatePath(ROUTE.MASTER_APPLICANTS, { applicant_type: 'master' })}`,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMISSION_MODERATOR,
          ROLES.AMO_CRM_OPERATOR_ADMISSION,
          ROLES.ADMISSION_APPOINTMENT_TEACHER,
          ROLES.ADMISSION_ARCHIVE_TASHKENT,
          ROLES.ADMISSION_ARCHIVE_SAMARKAND,
          ROLES.ADMISSION_ARCHIVE_OPERATOR,
          ROLES.ACCOUNTING_HEAD,
          ROLES.ACCOUNTING_HEAD_VIEWER,
        ],
      },
      {
        id: 'deletedApplicants',
        title: <FormattedMessage id='deletedApplicants' />,
        icon: <Users size={20} />,
        navLink: `${generatePath(ROUTE.DELETED_APPLICANTS, { applicant_type: 'deleted' })}`,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMISSION_MODERATOR,
          ROLES.AMO_CRM_OPERATOR_ADMISSION,
          ROLES.ADMISSION_APPOINTMENT_TEACHER,
          ROLES.ADMISSION_ARCHIVE_TASHKENT,
          ROLES.ADMISSION_ARCHIVE_SAMARKAND,
          ROLES.ADMISSION_ARCHIVE_OPERATOR,
          ROLES.ACCOUNTING_HEAD,
          ROLES.ACCOUNTING_HEAD_VIEWER,
        ],
      },
      {
        id: 'fullTimeApplicants',
        title: <FormattedMessage id='appointment' />,
        icon: <Users size={20} />,
        navLink: ROUTE.FULL_TIME_APPLICANTS,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMISSION_MODERATOR,
          ROLES.AMO_CRM_OPERATOR_ADMISSION,
          ROLES.ADMISSION_APPOINTMENT_TEACHER,
        ],
      },
    ],
  },
  {
    id: 'courseParent',
    title: <FormattedMessage id='course' />,
    icon: <Navigation size={20} />,
    navLink: ROUTE.COURSE,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.ADMIN_VIEWER,
    ],
    children: [
      {
        id: 'courseCreate',
        title: <FormattedMessage id='create' />,
        icon: <Circle size={20} />,
        navLink: ROUTE.COURSE_CREATE,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.REGISTRAR_OFFICER,
          ROLES.REGISTRAR_OFFICER_SAMARKAND,
          ROLES.ADMIN,
          ROLES.ADMIN_SAMARKAND,
          ROLES.ADMIN_VIEWER,
        ],
      },
      {
        id: 'courseActivate',
        title: <FormattedMessage id='activate' />,
        icon: <Circle size={20} />,
        navLink: ROUTE.COURSE_CAMPAIGN,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.REGISTRAR_OFFICER,
          ROLES.REGISTRAR_OFFICER_SAMARKAND,
          ROLES.ADMIN,
          ROLES.ADMIN_SAMARKAND,
          ROLES.ADMIN_VIEWER,
        ],
      },
    ],
  },
  {
    id: 'module',
    title: <FormattedMessage id='module' />,
    icon: <Hexagon size={20} />,
    navLink: ROUTE.MODULES,
    children: [
      {
        id: 'moduleCreate',
        title: <FormattedMessage id='create' />,
        icon: <Circle size={20} />,
        navLink: ROUTE.MODULES_CREATE,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMIN,
          ROLES.ADMIN_SAMARKAND,
          ROLES.REGISTRAR_HEAD,
          ROLES.REGISTRAR_OFFICER,
          ROLES.REGISTRAR_OFFICER_SAMARKAND,
          ROLES.QUALITY_ASSURANCE,
          ROLES.QUALITY_ASSURANCE_HEAD,
          ROLES.ADMIN_VIEWER,
        ],
      },
      {
        id: 'moduleActivate',
        title: <FormattedMessage id='activate' />,
        icon: <Circle size={20} />,
        navLink: ROUTE.CCLS_MODULE,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMIN,
          ROLES.ADMIN_SAMARKAND,
          ROLES.REGISTRAR_HEAD,
          ROLES.REGISTRAR_OFFICER,
          ROLES.REGISTRAR_OFFICER_SAMARKAND,
          ROLES.QUALITY_ASSURANCE,
          ROLES.QUALITY_ASSURANCE_HEAD,
          ROLES.COURSE_LEADER,
          ROLES.ADMIN_VIEWER,
        ],
      },
    ],
  },
  {
    id: 'lesson',
    title: <FormattedMessage id='lessons' />,
    icon: <Book size={20} />,
    navLink: ROUTE.LESSONS,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.REGISTRAR_HEAD,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.REGISTRAR_HELPER,
      ROLES.PROGRAMME_MANAGER,
      ROLES.TEACHING_AND_LEARNING_OFFICER,
      ROLES.TEACHER,
      ROLES.MODULE_LEADER,
      ROLES.QUALITY_ASSURANCE,
      ROLES.QUALITY_ASSURANCE_HEAD,
      ROLES.COURSE_LEADER,
      ROLES.DEAN,
      ROLES.SYSTEM_MODERATOR,
      ROLES.ADMIN_VIEWER,
    ],
  },
  {
    id: 'attendance-students',
    title: <FormattedMessage id='attendance' />,
    icon: <Clock size={20} />,
    navLink: ROUTE.ATTENDANCE_STUDENTS,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.REGISTRAR_HEAD,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.PROGRAMME_MANAGER,
      ROLES.TEACHING_AND_LEARNING_OFFICER,
      ROLES.TEACHER,
      ROLES.MODULE_LEADER,
      ROLES.QUALITY_ASSURANCE,
      ROLES.QUALITY_ASSURANCE_HEAD,
      ROLES.COURSE_LEADER,
      ROLES.STUDENTS_DEPARTMENT,
      ROLES.STUDENTS_DEPARTMENT_SAMARKAND,
      ROLES.ADMIN_VIEWER,
    ],
  },
  {
    id: 'attendanceList',
    title: <FormattedMessage id='Attendance Table' />,
    icon: <UserMinus size={20} />,
    navLink: ROUTE.ATTENDANCE_LIST,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.BACHELOR_STUDENT,
      ROLES.MASTER_STUDENT,
      ROLES.TEST_STUDENT,
      ROLES.TEACHER,
      ROLES.MODULE_LEADER,
      ROLES.PROGRAMME_MANAGER,
      ROLES.TEACHING_AND_LEARNING_OFFICER,
      ROLES.REGISTRAR_HEAD,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.REGISTRAR_HELPER,
      ROLES.ACADEMIC_MENTOR,
      ROLES.DEAN,
      ROLES.QUALITY_ASSURANCE,
      ROLES.QUALITY_ASSURANCE_HEAD,
      ROLES.COURSE_LEADER,
      ROLES.STUDENTS_DEPARTMENT,
      ROLES.STUDENTS_DEPARTMENT_SAMARKAND,
      ROLES.AMO_CRM_OPERATOR,
      ROLES.ADMIN_VIEWER,
    ],
  },
  {
    id: 'Cvs',
    title: 'CVs',
    icon: <FileText size={20} />,
    navLink: ROUTE.STUDENT_CV,
    children: [
      {
        id: 'StudentCV',
        title: <FormattedMessage id='studentCV' />,
        icon: <FileText size={20} />,
        navLink: ROUTE.STUDENT_CV,
        access: [ROLES.SUPER_ADMIN, ROLES.CAREER_CENTER_MANAGER, ROLES.DEAN],
      },
      {
        id: 'StudentCVCreate',
        title: 'CV',
        icon: <FileText size={20} />,
        navLink: ROUTE.STUDENT_CV_CREATE,
        access: [ROLES.SUPER_ADMIN, ROLES.BACHELOR_STUDENT],
      },
      {
        id: 'JobOffer',
        title: <FormattedMessage id='jobOffer' />,
        icon: <Briefcase size={20} />,
        navLink: ROUTE.JOB_OFFER,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.BACHELOR_STUDENT,
          ROLES.STAFF,
          ROLES.MS_STUDENT,
          ROLES.MASTER_STUDENT,
          ROLES.TEST_STUDENT,
        ],
        badge: store.getState()?.Auth?.userInfo?.need_for_job_status === 'UPDATE_REQUIRED' ? 'warning' : 'danger',
        badgeText:
          store.getState()?.Auth?.userInfo?.need_for_job_status === 'UPDATE_REQUIRED'
            ? '!'
            : store.getState()?.Auth?.userInfo?.new_job_offers || null,
      },
    ],
  },
  {
    id: 'studentDocs',
    title: <FormattedMessage id='Xatlar' />,
    icon: <MessageSquare size={20} />,
    navLink: ROUTE.STUDENT_DOCS,
    access: [ROLES.BACHELOR_STUDENT, ROLES.MASTER_STUDENT, ROLES.SUPER_ADMIN],
  },
  {
    id: 'attendanceReason',
    title: <FormattedMessage id='attendanceReason' />,
    icon: <CheckCircle size={20} />,
    navLink: ROUTE.ATTENDANCE_REASON,
    access: [ROLES.SUPER_ADMIN, ROLES.STUDENTS_DEPARTMENT, ROLES.STUDENTS_DEPARTMENT_SAMARKAND, ROLES.ADMIN_VIEWER],
  },
  {
    id: 'markLessons',
    title: <FormattedMessage id='markLessons' />,
    icon: <Award size={20} />,
    navLink: ROUTE.MARK_LESSON,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.REGISTRAR_HEAD,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.REGISTRAR_HELPER,
      ROLES.PROGRAMME_MANAGER,
      ROLES.TEACHING_AND_LEARNING_OFFICER,
      ROLES.TEACHER,
      ROLES.MODULE_LEADER,
      ROLES.QUALITY_ASSURANCE,
      ROLES.QUALITY_ASSURANCE_HEAD,
      ROLES.DEAN,
      ROLES.COURSE_LEADER,
      ROLES.STUDENTS_DEPARTMENT,
      ROLES.STUDENTS_DEPARTMENT_SAMARKAND,
      ROLES.ADMIN_VIEWER,
    ],
  },
  {
    id: 'markAssessments',
    title: <FormattedMessage id='markAssessments' />,
    icon: <Award size={20} />,
    navLink: ROUTE.MARK_ASSESSMENT,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.DEAN,
      ROLES.QUALITY_ASSURANCE,
      ROLES.QUALITY_ASSURANCE_HEAD,
      ROLES.REGISTRAR_HEAD,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.TEACHER,
      ROLES.MODULE_LEADER,
      ROLES.COURSE_LEADER,
      ROLES.STUDENTS_DEPARTMENT,
      ROLES.STUDENTS_DEPARTMENT_SAMARKAND,
      ROLES.ADMIN_VIEWER,
    ],
  },
  {
    id: 'module-list',
    title: <FormattedMessage id='modules' />,
    icon: <Circle size={20} />,
    navLink: ROUTE.CCLS_MODULE,
    access: [],
  },
  {
    id: 'groups',
    title: <FormattedMessage id='groups' />,
    icon: <Grid size={20} />,
    navLink: ROUTE.GROUPS,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.REGISTRAR_HEAD,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.REGISTRAR_HELPER,
      ROLES.PROGRAMME_MANAGER,
      ROLES.DEAN,
      ROLES.QUALITY_ASSURANCE,
      ROLES.QUALITY_ASSURANCE_HEAD,
      ROLES.COURSE_LEADER,
      ROLES.ADMIN_VIEWER,
    ],
    children: [
      {
        id: 'groupsCreate',
        title: <FormattedMessage id='Grouping' />,
        icon: <Grid size={20} />,
        navLink: ROUTE.GROUPING,
        access: [
          ROLES.SUPER_ADMIN,
          ROLES.ADMIN,
          ROLES.ADMIN_SAMARKAND,
          ROLES.REGISTRAR_HEAD,
          ROLES.REGISTRAR_OFFICER,
          ROLES.REGISTRAR_OFFICER_SAMARKAND,
          ROLES.REGISTRAR_HELPER,
          ROLES.PROGRAMME_MANAGER,
          ROLES.DEAN,
          ROLES.QUALITY_ASSURANCE,
          ROLES.QUALITY_ASSURANCE_HEAD,
          ROLES.COURSE_LEADER,
          ROLES.STUDENTS_DEPARTMENT,
          ROLES.STUDENTS_DEPARTMENT_SAMARKAND,
          ROLES.ADMIN_VIEWER,
        ],
      },
      {
        id: 'groupsAssign',
        title: <FormattedMessage id='assignGroups' />,
        icon: <Clipboard size={20} />,
        navLink: ROUTE.GROUP_ASSIGN,
        access: [ROLES.SUPER_ADMIN],
      },
    ],
  },
  {
    id: 'enrollmentList',
    title: <FormattedMessage id='enrollmentList' />,
    icon: <Check size={20} />,
    navLink: ROUTE.ENROLLMENT,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.REGISTRAR_HEAD,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.REGISTRAR_HELPER,
      ROLES.MODERATOR_VIEWER,
      ROLES.COURSE_LEADER,
    ],
  },
  {
    id: 'internalExamPlace',
    title: 'Internal Exam Place',
    icon: <Check size={20} />,
    navLink: ROUTE.INTERNAL_EXAM_PLACE,
    access: [
      ROLES.SUPER_ADMIN,
      // ROLES.REGISTRAR_HEAD,
      // ROLES.REGISTRAR_OFFICER,
      // ROLES.REGISTRAR_HELPER,
      // ROLES.MODERATOR_VIEWER,
    ],
  },
  {
    id: 'courseTransfer',
    title: 'Course Transfer',
    icon: <Users size={20} />,
    navLink: ROUTE.COURSE_TRANSFER,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.STUDENTS_DEPARTMENT,
      ROLES.STUDENTS_DEPARTMENT_SAMARKAND,
    ],
  },

  {
    id: 'userInfoHistory',
    title: 'User Info History',
    icon: <List size={20} />,
    navLink: ROUTE.USER_INFO_HISTORY,
    access: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
  },

  // {
  //   id: 'calendar',
  //   title: 'Calendar',
  //   icon: <Calendar size={20} />,
  //   navLink: ROUTE.CALENDAR,
  //   access: [
  //     ROLES.SUPER_ADMIN,
  //     ROLES.BACHELOR_STUDENT,
  //     ROLES.TEST_STUDENT,
  //     ROLES.QUALITY_ASSURANCE,
  //     ROLES.REGISTRAR_HEAD,
  //     ROLES.REGISTRAR_OFFICER,
  //     ROLES.REGISTRAR_HELPER,
  //     ROLES.PROGRAMME_MANAGER,
  //     ROLES.TEACHING_AND_LEARNING_OFFICER,
  //     ROLES.TEACHER,
  //     ROLES.MODULE_LEADER,
  //     ROLES.MODERATOR_VIEWER,
  //   ],
  // },
  {
    header: 'Assessment',
    access: [ROLES.SUPER_ADMIN, ROLES.EXAM_HEAD],
  },
  {
    id: 'marks-entry',
    title: 'Mark entry',
    icon: <PlusCircle size={20} />,
    navLink: ROUTE.ASSESSMENT_MARK_ENTRY_LIST,
    access: [ROLES.SUPER_ADMIN, ROLES.EXAM_HEAD],
  },
  {
    id: 'marks',
    title: 'Marks',
    icon: <CheckCircle size={20} />,
    navLink: '/marks',
    access: [ROLES.SUPER_ADMIN],
  },
  {
    id: 'mark-amendment',
    title: 'Mark amendments',
    icon: <Edit size={20} />,
    navLink: '/mark-amendment',
    access: [ROLES.SUPER_ADMIN],
  },
  {
    header: 'Academic misconduct',
    access: [ROLES.SUPER_ADMIN],
  },
  {
    id: 'offence',
    title: 'Offence',
    icon: <Meh size={20} />,
    navLink: '/offence',
    access: [ROLES.SUPER_ADMIN],
  },
  {
    id: 'reportMisconduct',
    title: 'Report misconduct',
    icon: <Meh size={20} />,
    navLink: '/report-misconduct',
    access: [ROLES.SUPER_ADMIN],
  },

  {
    header: 'Extenuating circumstances',
    access: [ROLES.SUPER_ADMIN],
  },
  {
    id: 'ecSubmit',
    title: 'Submit EC',
    icon: <AlertOctagon size={20} />,
    navLink: '/ec-submit',
    // eslint-disable-next-line array-bracket-spacing
    access: [ROLES.SUPER_ADMIN /*, ROLES.BACHELOR_STUDENT, ROLES.MASTER_STUDENT, ROLES.TEST_STUDENT*/],
  },
  {
    id: 'ec',
    title: 'Extenuating Circumstances',
    icon: <AlertOctagon size={20} />,
    navLink: '/ec',
    access: [ROLES.SUPER_ADMIN],
  },
  {
    header: 'Report',
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.REGISTRAR_HEAD,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.MODERATOR_VIEWER,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.ACCOUNTING_HEAD,
      ROLES.ACCOUNTING_HEAD_VIEWER,
      ROLES.COURSE_LEADER,
    ],
  },
  {
    id: 'report',
    title: 'Report',
    icon: <FileText size={20} />,
    navLink: ROUTE.REPORT,
    access: [
      ROLES.SUPER_ADMIN,
      ROLES.ADMIN,
      ROLES.ADMIN_SAMARKAND,
      ROLES.REGISTRAR_HEAD,
      ROLES.REGISTRAR_OFFICER,
      ROLES.REGISTRAR_OFFICER_SAMARKAND,
      ROLES.MODERATOR_VIEWER,
      ROLES.QUALITY_ASSURANCE,
      ROLES.QUALITY_ASSURANCE_HEAD,
      ROLES.ACCOUNTING_HEAD,
      ROLES.ACCOUNTING_HEAD_VIEWER,
      ROLES.COURSE_LEADER,
    ],
  },
  {
    header: 'Outcome',
    access: [ROLES.SUPER_ADMIN],
  },
  {
    id: 'modulesOutcomes',
    title: 'Modules outcome',
    icon: <Book size={20} />,
    navLink: '/modules-outcome',
    access: [ROLES.SUPER_ADMIN],
  },
  {
    header: 'Files',
    access: [ROLES.SUPER_ADMIN],
  },
  {
    id: 'moduleFiles',
    title: 'Module files',
    icon: <File size={20} />,
    navLink: '/module-files',
    access: [ROLES.SUPER_ADMIN],
  },
  {
    id: 'policyFiles',
    title: 'Policy files',
    icon: <File size={20} />,
    navLink: '/policy-files',
    access: [ROLES.SUPER_ADMIN],
  },
  {
    header: 'Achievement Records',
    access: [ROLES.SUPER_ADMIN],
  },
  {
    id: 'achievementRecords',
    title: 'Achievement Records',
    icon: <Award size={20} />,
    navLink: '/achievement-records',
    access: [ROLES.SUPER_ADMIN],
  },
  {
    id: 'achievementRecordsInfo',
    title: 'Achievement Records',
    icon: <Award size={20} />,
    navLink: '/achievement-records/info',
    access: [ROLES.SUPER_ADMIN],
  },
  // {
  //   header: 'Attendance',
  //   access: [
  //     ROLES.SUPER_ADMIN,
  //     ROLES.BACHELOR_STUDENT,
  //     ROLES.TEST_STUDENT,
  //     ROLES.TEACHER,
  //     ROLES.MODULE_LEADER,
  //     ROLES.PROGRAMME_MANAGER,
  //     ROLES.REGISTRAR_HEAD,
  //     ROLES.REGISTRAR_OFFICER,
  //     ROLES.REGISTRAR_HELPER,
  //   ],
  // },
  // {
  //   id: 'attendance',
  //   title: 'Attendance',
  //   icon: <UserMinus size={20} />,
  //   navLink: '/attendance',
  //   access: [ROLES.SUPER_ADMIN, ROLES.TEACHER, ROLES.MODULE_LEADER, ROLES.TEACHING_AND_LEARNING_OFFICER],
  // },
  // {
  //   id: 'exam',
  //   title: 'Switch to exam',
  //   icon: <BsQuestionCircle size={20} />,
  //   externalLink: true,
  //   tag: BlankButton,
  //   newTab: true,
  //   onClick: dispatch => {
  //     dispatch((dispatch, getStore) => {
  //       const store = getStore()
  //       const { pathname } = store.router.location
  //       dispatch(push({ pathname, search: qs.stringify({ token: store.Auth.token, path: '/' }) }))
  //     })
  //   },
  //   navLink: 'http://192.168.100.57:3069',
  //   programmatically: true,
  //   access: [ROLES.SUPER_ADMIN],
  // },

  // {
  //   header: 'Forms',
  //   access: [
  //     ROLES.SUPER_ADMIN,
  //     // ROLES.BACHELOR_STUDENT,
  //     // ROLES.MASTER_STUDENT,
  //     // ROLES.TEST_STUDENT,
  //     ROLES.REGISTRAR_HEAD,
  //     ROLES.REGISTRAR_OFFICER,
  //     ROLES.REGISTRAR_HELPER,
  //   ],
  // },
  // {
  //   id: 'studentFormRequests',
  //   title: 'Form requests',
  //   icon: <FilePlus size={20} />,
  //   navLink: '/forms-for-student',
  //   access: [ROLES.SUPER_ADMIN, ROLES.BACHELOR_STUDENT, ROLES.TEST_STUDENT],
  // },
  // {
  //   id: 'incomingForms',
  //   title: 'Incoming forms',
  //   icon: <FilePlus size={20} />,
  //   navLink: '/incoming-forms',
  //   access: [ROLES.SUPER_ADMIN, ROLES.REGISTRAR_HEAD, ROLES.REGISTRAR_OFFICER, ROLES.REGISTRAR_HELPER],
  // },
  {
    id: 'footer',
    header: <Footer />,
  },
]
