import {
  ADMIN,
  BACHELOR_STUDENT,
  COURSE_LEADER,
  MASTER_STUDENT,
  MODERATOR_VIEWER,
  REGISTRAR_HEAD,
  REGISTRAR_HELPER,
  REGISTRAR_OFFICER,
  REGISTRAR_OFFICER_SAMARKAND,
  SUPER_ADMIN,
  TEST_STUDENT,
} from '@constants/userRoles'
import { lazy } from 'react'

import { ENROLLMENT, ENROLLMENT_PAGE } from '../constants/route'

export const EnrollmentListRoutes = [
  {
    path: ENROLLMENT,
    component: lazy(() => import('../views/EnrollmentList')),
    exact: true,
    meta: {
      access: [
        SUPER_ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_OFFICER_SAMARKAND,
        REGISTRAR_HELPER,
        MODERATOR_VIEWER,
        COURSE_LEADER,
        ADMIN,
      ],
    },
  },
  {
    path: ENROLLMENT_PAGE,
    component: lazy(() => import('../views/EnrollmentPage')),
    exact: true,
    layout: 'BlankLayout',
    meta: {
      access: [TEST_STUDENT, BACHELOR_STUDENT, SUPER_ADMIN, MASTER_STUDENT],
      privateRoute: true,
    },
  },
]
