import { ACCOUNTING_CONTRACTS } from '@constants/routes'
import { ACCOUNTING_HEAD, ACCOUNTING_HEAD_VIEWER, SUPER_ADMIN } from '@constants/userRoles'
import { lazy } from 'react'

export const AccountingRoutes = [
  {
    path: ACCOUNTING_CONTRACTS,
    component: lazy(() => import('../views/Accounting')),
    meta: {
      access: [SUPER_ADMIN, ACCOUNTING_HEAD, ACCOUNTING_HEAD_VIEWER],
    },
  },
]
