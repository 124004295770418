import * as roles from '@constants/userRoles'
import { REGISTRAR_OFFICER_SAMARKAND } from '@constants/userRoles'
import { lazy } from 'react'

import { MODULES_CREATE } from '../constants/route'

export const ModulesCreateRoutes = [
  {
    path: MODULES_CREATE,
    component: lazy(() => import('../views/Modules')),
    meta: {
      access: [
        roles.SUPER_ADMIN,
        roles.ADMIN,
        roles.ADMIN_SAMARKAND,
        roles.QUALITY_ASSURANCE,
        roles.QUALITY_ASSURANCE_HEAD,
        roles.MODERATOR_VIEWER,
        roles.REGISTRAR_HEAD,
        roles.REGISTRAR_OFFICER,
        roles.REGISTRAR_OFFICER_SAMARKAND,
        roles.ADMIN_VIEWER,
      ],
    },
  },
]
