import { moduleFilesTypes, studentPolicyFilesTypes } from '@constants/types/moduleFilesTypes'
import {
  BACHELOR_STUDENT,
  MODERATOR_VIEWER,
  MODULE_LEADER,
  REGISTRAR_HEAD,
  REGISTRAR_HELPER,
  REGISTRAR_OFFICER,
  REGISTRAR_OFFICER_SAMARKAND,
  SUPER_ADMIN,
  TEACHER,
  TEST_STUDENT,
} from '@constants/userRoles'
import { lazy } from 'react'

import { MODULE_FILES, POLICY_FILES, STUDENT_FILES } from '../constants/route'

export const FileRoutes = [
  {
    path: MODULE_FILES,
    component: lazy(() => import('../views/ModuleFile')),
    componentProps: {
      title: 'Module Files',
      label: 'Module-files',
      types: moduleFilesTypes,
      modalTitle: 'Add module files',
    },
    meta: {
      access: [
        SUPER_ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_OFFICER_SAMARKAND,
        REGISTRAR_HELPER,
        MODERATOR_VIEWER,
        MODULE_LEADER,
        TEACHER,
      ],
    },
  },
  {
    path: POLICY_FILES,
    component: lazy(() => import('../views/ModuleFile')),
    componentProps: {
      title: 'Policy Files',
      label: 'Policy-files',
      types: studentPolicyFilesTypes,
      modalTitle: 'Add policy files',
    },
    meta: {
      access: [
        SUPER_ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_OFFICER_SAMARKAND,
        REGISTRAR_HELPER,
        MODERATOR_VIEWER,
      ],
    },
  },
  {
    path: STUDENT_FILES,
    component: lazy(() => import('../views/StudentFiles')),
    componentProps: {
      title: 'Policies',
      type: 2,
      label: 'Policies',
    },
    meta: {
      access: [
        SUPER_ADMIN,
        BACHELOR_STUDENT,
        TEST_STUDENT,
        TEACHER,
        MODULE_LEADER,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_OFFICER_SAMARKAND,
        REGISTRAR_HELPER,
      ],
    },
  },
]
