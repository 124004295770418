import {
  ADMIN,
  ADMIN_SAMARKAND,
  ADMIN_VIEWER,
  STUDENTS_DEPARTMENT,
  STUDENTS_DEPARTMENT_SAMARKAND,
  SUPER_ADMIN,
} from '@constants/userRoles'
import { lazy } from 'react'

import { COURSE_TRANSFER } from '../constants/route'

export const CourseTransferRoutes = [
  {
    path: COURSE_TRANSFER,
    component: lazy(() => import('../views/CourseTransfer')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, ADMIN, ADMIN_SAMARKAND, STUDENTS_DEPARTMENT, STUDENTS_DEPARTMENT_SAMARKAND],
    },
  },
]
