import * as roles from '@constants/userRoles'
import { REGISTRAR_OFFICER_SAMARKAND } from '@constants/userRoles'
import { lazy } from 'react'

import { COURSE_CAMPAIGN } from '../constants/route'

export const CourseCampaignRoutes = [
  {
    path: COURSE_CAMPAIGN,
    component: lazy(() => import('../../activate/views/CourseCampaign')),
    meta: {
      access: [
        roles.SUPER_ADMIN,
        roles.ADMIN,
        roles.ADMIN_SAMARKAND,
        roles.QUALITY_ASSURANCE,
        roles.QUALITY_ASSURANCE_HEAD,
        roles.MODERATOR_VIEWER,
        roles.REGISTRAR_OFFICER,
        roles.REGISTRAR_OFFICER_SAMARKAND,
        roles.ADMIN_VIEWER,
      ],
    },
  },
]
