import {
  MODERATOR_VIEWER,
  MODULE_LEADER,
  REGISTRAR_HEAD,
  REGISTRAR_OFFICER,
  REGISTRAR_OFFICER_SAMARKAND,
  SUPER_ADMIN,
} from '@constants/userRoles'
import { lazy } from 'react'

import { MODULES_OUTCOME } from '../constants/route'

export const ModulesOutcomeRoutes = [
  {
    path: MODULES_OUTCOME,
    component: lazy(() => import('../views/ModulesOutcome')),
    meta: {
      access: [
        SUPER_ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_OFFICER_SAMARKAND,
        MODERATOR_VIEWER,
        MODULE_LEADER,
      ],
    },
  },
]
