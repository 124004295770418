import {
  BACHELOR_STUDENT,
  MODERATOR_VIEWER,
  REGISTRAR_HEAD,
  REGISTRAR_HELPER,
  REGISTRAR_OFFICER,
  REGISTRAR_OFFICER_SAMARKAND,
  SUPER_ADMIN,
  TEST_STUDENT,
} from '@constants/userRoles'
import { lazy } from 'react'

import { ASSESSMENT_EXTENUATING_CIRCUMSTANCES, ASSESSMENT_EXTENUATING_CIRCUMSTANCES_ADMIN } from '../constants/route'

export const ECRoutes = [
  {
    path: ASSESSMENT_EXTENUATING_CIRCUMSTANCES,
    component: lazy(() => import('../views/ECSubmit')),
    meta: {
      access: [SUPER_ADMIN, TEST_STUDENT, BACHELOR_STUDENT],
    },
  },
  {
    path: ASSESSMENT_EXTENUATING_CIRCUMSTANCES_ADMIN,
    component: lazy(() => import('../views/ExtenuatingCircumstances')),
    meta: {
      access: [
        SUPER_ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_OFFICER_SAMARKAND,
        REGISTRAR_HELPER,
        MODERATOR_VIEWER,
      ],
    },
  },
]
