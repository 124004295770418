import {
  ACCOUNTING_HEAD,
  ACCOUNTING_HEAD_VIEWER,
  ADMIN,
  COURSE_LEADER,
  DEAN,
  MODERATOR_VIEWER,
  QUALITY_ASSURANCE,
  QUALITY_ASSURANCE_HEAD,
  REGISTRAR_HEAD,
  REGISTRAR_OFFICER,
  REGISTRAR_OFFICER_SAMARKAND,
  SUPER_ADMIN,
} from '@constants/userRoles'
import { lazy } from 'react'

import { REPORT, REPORT_VIEW } from '../constants/route'

export const ReportRoutes = [
  {
    path: REPORT,
    exact: true,
    component: lazy(() => import('../views/Report')),
    meta: {
      access: [
        SUPER_ADMIN,
        ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_OFFICER_SAMARKAND,
        MODERATOR_VIEWER,
        QUALITY_ASSURANCE,
        QUALITY_ASSURANCE_HEAD,
        DEAN,
        ACCOUNTING_HEAD,
        ACCOUNTING_HEAD_VIEWER,
        COURSE_LEADER,
      ],
    },
  },
  {
    path: REPORT_VIEW,
    component: lazy(() => import('../views/ReportView')),
    meta: {
      access: [
        SUPER_ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_OFFICER_SAMARKAND,
        MODERATOR_VIEWER,
        QUALITY_ASSURANCE,
        QUALITY_ASSURANCE_HEAD,
        DEAN,
        ACCOUNTING_HEAD,
        ACCOUNTING_HEAD_VIEWER,
        COURSE_LEADER,
      ],
    },
  },
]
