import { toastError } from '@helpers/toastError'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as Api from '../api'
import {
  GENERATE_TRANSCRIPT,
  STUDENT,
  STUDENT_ACHIEVEMENT_RECORDS,
  STUDENT_ALL,
  STUDENT_ALLOW_EXAM,
  STUDENT_CALL_LETTER_DELETE,
  STUDENT_CONTACT_INFO,
  STUDENT_DEBIT_DETAIL_CREATE,
  STUDENT_DEBIT_DETAIL_DELETE,
  STUDENT_DELETE,
  STUDENT_DOCUMENT,
  STUDENT_EXPORT_DATA,
  STUDENT_GUARD_CREATE,
  STUDENT_GUARD_LIST,
  STUDENT_INVOICE_CREATE,
  STUDENT_LIST,
  STUDENT_MARK_UPDATE,
  STUDENT_PERSONAL_INFO,
  STUDENT_PROFILE,
  STUDENT_PROFILE_UPDATE,
  STUDENT_RESUME_STUDY,
  STUDENT_SEND_SMS,
  STUDENT_STATUS_UPDATE,
  STUDENT_TRANSFER,
  STUDENT_UPDATE_PASSPORT,
  STUDENT_USERNAME,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const student = createAsyncThunk(
  STUDENT,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await Api.student(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentDelete = createAsyncThunk(
  STUDENT_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT}{ids}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentProfile = createAsyncThunk(
  STUDENT_PROFILE,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_PROFILE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentProfile(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentProfileUpdate = createAsyncThunk(
  STUDENT_PROFILE_UPDATE,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_PROFILE}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentProfileUpdate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentProfileClear = createAction(`${STUDENT_PROFILE}/clear`)

export const studentClear = createAction(`${STUDENT}/clear`)

export const studentList = createAsyncThunk(
  STUDENT_LIST,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentListClear = createAction(`${STUDENT_LIST}/clear`)

export const studentAchievementRecords = createAsyncThunk(
  STUDENT_ACHIEVEMENT_RECORDS,
  async (
    params: PayloadCreatorParams<`/${typeof API.ACHIEVEMENT_RECORDS_STUDENT}{id}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await Api.studentAchievementRecords(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentAchievementRecordsClear = createAction(`${STUDENT_ACHIEVEMENT_RECORDS}/clear`)

export const studentAll = createAsyncThunk(
  STUDENT_ALL,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentAllClear = createAction(`${STUDENT_ALL}/clear`)

export const studentGuardCreate = createAsyncThunk(
  STUDENT_GUARD_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_GUARD}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentGuardCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentDebitDetailCreate = createAsyncThunk(
  STUDENT_DEBIT_DETAIL_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_DEBIT_DETAIL}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentDebitDetailCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentResumeStudy = createAsyncThunk(
  STUDENT_RESUME_STUDY,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_RESUME_STUDY}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentResumeStudy(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentDebitDetailDelete = createAsyncThunk(
  STUDENT_DEBIT_DETAIL_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_DEBIT_DETAIL}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentDebitDetailDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentCallLetterDelete = createAsyncThunk(
  STUDENT_CALL_LETTER_DELETE,
  async (
    params: PayloadCreatorParams<`${typeof API.STUDENT_CALL_LETTER_DELETE}{id}`, 'delete'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await Api.studentCallLetterDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentGuardList = createAsyncThunk(
  STUDENT_GUARD_LIST,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_GUARD}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentGuard(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentGuardListClear = createAction(`${STUDENT_GUARD_LIST}/clear`)

export const studentUsername = createAsyncThunk(
  STUDENT_USERNAME,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_USERNAME}{username}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentUsername(omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentUsernameClear = createAction(`${STUDENT_USERNAME}`)

export const studentPersonalInfo = createAsyncThunk(
  STUDENT_PERSONAL_INFO,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_PERSONAL_INFO}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentPersonalInfo(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentContactInfo = createAsyncThunk(
  STUDENT_CONTACT_INFO,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_CONTACT_INFO}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentContactInfo(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentStatusUpdate = createAsyncThunk(
  STUDENT_STATUS_UPDATE,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await Api.updateStudentStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentMarkUpdate = createAsyncThunk(
  STUDENT_MARK_UPDATE,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_MARK_UPDATE}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentMarkUpdate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generateTranscript = createAsyncThunk(
  GENERATE_TRANSCRIPT,
  async (params: PayloadCreatorParams<`${typeof API.GENERATE_TRANSCRIPT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await Api.generateTranscript(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentSendSms = createAsyncThunk(
  STUDENT_SEND_SMS,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_SEND_SMS}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentSendSms(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentTransfer = createAsyncThunk(
  STUDENT_TRANSFER,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_TRANSFER}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentTransfer(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentExportData = createAsyncThunk(
  STUDENT_EXPORT_DATA,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_EXPORT_DATA}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentExportData(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentDocumentCreate = createAsyncThunk(
  STUDENT_DOCUMENT,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_DOCUMENT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentDocumentCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentInvoiceCreate = createAsyncThunk(
  STUDENT_INVOICE_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_INVOICE_CREATE}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentInvoiceCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentUpdatePassport = createAsyncThunk(
  STUDENT_UPDATE_PASSPORT,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_UPDATE_PASSPORT}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentUpdatePassport(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentAllowExam = createAsyncThunk(
  STUDENT_ALLOW_EXAM,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_ALLOW_EXAM}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await Api.studentAllowExam(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)
